import Http from "../http/Http";
import {AxiosInstance} from "axios";
import {AddUserRequest, User} from "@packages/contracts/user";
import {Paginated} from "@packages/contracts/paginated";
import {SuccessResponse} from "@packages/contracts/success-response";
import {searchResultInterface} from "@/stores/search";
import {NewAccessTokenSummary, NewVerificationCode} from "@packages/contracts/auth";
import {HasRole} from "@packages/contracts/has-role";
import {BranchUser} from "@packages/contracts/branch/branch";
import {FirmUser} from "@packages/contracts/firm/firm";

const baseUrl: string = import.meta.env.VITE_API_BASE_V2 + '/users'

const http = new Http(baseUrl);

const axios: AxiosInstance = http.axios;

export type UserWithBranchFirmRelations = User & {
    roles: HasRole[]
    branch_users: BranchUser[]
    firm_users: FirmUser[]
}

export const getBranchsUsers = async (parameters: any = {}): Promise<SuccessResponse<{
    users: Paginated<UserWithBranchFirmRelations>,
    add_user_requests: AddUserRequest[]
}>> => {
    return await axios.get('get-branchs-users', {params: parameters})
}

export const getFirmsUsers = async (parameters: any = {}): Promise<SuccessResponse<{
    users: Paginated<UserWithBranchFirmRelations>,
    add_user_requests: AddUserRequest[]
}>> => {
    return await axios.get('get-firms-users', {params: parameters})
}

export const getFirmsUsersOnly = async (parameters: any = {}): Promise<SuccessResponse<Paginated<UserWithBranchFirmRelations>>> => {
    return await axios.get('get-firms-users-only', {params: parameters})
}

export const updateUserAndBranchUser = async (branchUserId: number, data: any): Promise<SuccessResponse<{
    data: User
}>> => {
    return await axios.put(`/branch_user/${branchUserId}/update-user-and-branch-user`, data)
}

export const getUserWithCurrentBranch = async (userId: number, parameters: any = {relations: ['branches']}): Promise<SuccessResponse<User>> => {
    return await axios.get('get-user-with-current-branch', {params: {...parameters, ...{id: userId}}})
}

export const createUser = async (data: any): Promise<SuccessResponse<User>> => {
    return await axios.post(`create-user`, data)
}

export const updateUser = async (userId: number, data: any): Promise<SuccessResponse<User>> => {
    return await axios.put(`user/${userId}/update-user`, data)
}

export const createUserAndBranchUser = async (data: any): Promise<SuccessResponse<User>> => {
    return await axios.post(`create-user-and-branch-user`, data)
}

export const searchBranchsUsers = async (search: string = '', limit: number = 50, otherParameters: {
    [key: string]: string
} = {}): Promise<SuccessResponse<searchResultInterface[]>> => {
    return await axios.get('search-branchs-users', {params: Object.assign(otherParameters, {q: search, limit: limit})})
}

export const searchUsersThatDoNotProvideTheService = async (productId: number, search: string = '', perPage: number | null = null, sortBy: null | string = null, sortDirection: 'asc' | 'desc' | null = null): Promise<SuccessResponse<searchResultInterface[]>> => {
    return await axios.get('search-users-not-attached-to-product', {
        params: {
            product_id: productId,
            search: search,
            perPage: perPage,
            sortBy: sortBy,
            sortDirection: sortDirection
        }
    })
}

/**
 * response.data.data will be true if the user gets signed out through this request, and it will be false otherwise.
 *
 * Note: response.data.data may be false because the user has already been signed out.
 */
export async function logOutUser(): Promise<SuccessResponse<boolean>> {
    return axios.delete('auth/sign-out')
}

export async function loginFirstFactor(password: string, email: string | null = null, phone: string | null = null): Promise<SuccessResponse<NewVerificationCode>> {
    return axios.post('auth/login/1', {
        password,
        email,
        phone
    })
}

export async function loginSecondFactor(code: string, token: string): Promise<SuccessResponse<NewAccessTokenSummary>> {
    return axios.post('auth/login/2', {
        code,
        token,
    })
}

export async function searchBranchsUsersAndFirmOfficials(search: string = '', limit: number = 25, otherParameters: {
    [key: string]: string
} = {}): Promise<SuccessResponse<searchResultInterface[]>> {
    return await axios.get('search-buafo', {
        params: {
            ...otherParameters, ...{q: search, limit: limit}
        }
    })
}

export async function updateUsersGeneralInformation(data: {
    name: string,
    last_name: string,
    title: string | null
}): Promise<SuccessResponse<User>> {
    return axios.put('user/update/general', {user: data})
}

export async function requestToUpdateUsersPhone(phone: string): Promise<SuccessResponse<NewVerificationCode>> {
    return axios.put('user/update/phone/request', {phone})
}

export async function verifyToUpdateUsersPhone(verificationCode: string, token: string): Promise<SuccessResponse<User>> {
    return axios.put('user/update/phone/verify', {code: verificationCode, token})
}

export async function requestToUpdateUsersEmail(email: string): Promise<SuccessResponse<{ expires_at: string }>> {
    return axios.put('user/update/email/request', {email})
}

export async function verifyToUpdateUsersEmail(token: string): Promise<SuccessResponse<User>> {
    return axios.put('user/update/email/verify', {token})
}

export async function changePassword(
    currentPassword: string | null, newPasswordInputs: { main: string | null, confirmation: string | null }
): Promise<SuccessResponse<User>> {
    return axios.put('user/update/cp', {
        current: {
            password: currentPassword,
        },
        new: {
            password: newPasswordInputs.main,
            password_confirmation: newPasswordInputs.confirmation,
        }
    })
}

/**
 * Gets the authenticated user.
 */
export async function getUser(): Promise<SuccessResponse<User>> {
    return axios.get('user/get')
}

export type BannedResponse = { is_banned: boolean }

/**
 * Check if the users IP address is banned or not.
 */
export async function checkUsersIp() {
    return axios.post('auth/user-ip/check')
}
