import {GeneralPrefixes} from "@packages/composables/session-storage/GeneralPrefixes";
import type {RouteRecordRaw} from "vue-router";
import {
    verifyPhoneOfExistingUserThatWillLogIn,
    verifyPhoneOfNewUserToAddUser
} from "@/requests/requests/branches-requests";

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        component: () =>
            import(/* webpackChunkName: "layout" */ "@/layouts/default-layout.vue"),
        meta: {auth: true},
        children: [
            {
                path: "/",
                name: "dashboard",
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/dashboard.vue"
                        ),
                children: [
                    {
                        path: "/",
                        name: "calendar",
                        meta: {
                            generalPrefix: GeneralPrefixes.Calendar
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "calendar" */ "@/components/calendar/calendar.vue"
                                ),
                    },
                    {
                        path: "/scheduler-calendar",
                        name: "scheduler-calendar",
                        meta: {
                            generalPrefix: GeneralPrefixes.SchedulerCalendar
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "scheduler-calendar" */ "@/components/calendar/scheduler-calendar.vue"
                                ),
                    },
                    {
                        path: "/um-update-calendar",
                        name: "um-update-calendar",
                        meta: {
                            generalPrefix: GeneralPrefixes.UMUpdateCalendar
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "um-update-calendar" */ "@/components/calendar/um-update-calendar.vue"
                                ),
                    }
                ],
            },
            {
                path: "/services",
                name: "services",
                meta: {
                    index: 0,
                },
                component: () =>
                    import("@/views/products/ServicesPage.vue"),
                children: [
                    {
                        path: "/services/table-and-filter",
                        name: "services-table-and-filter",
                        component: () =>
                            import(
                                "@/views/products/services/table-and-filter.vue"
                                ),
                        meta: {
                            index: 0,
                            active: true,
                        }
                    },
                    {
                        path: "/services/sequence-groups",
                        name: "products-sequence-groups-table-and-filter",
                        component: () =>
                            import(
                                "@/views/products/products-sequence-groups/table-and-filter/table-and-filter.vue"
                                ),
                        meta: {
                            index: 1
                        }
                    },
                ],
            },
            {
                path: "/services/sequence-groups/:id",
                name: "services-update-sequence-group",
                component: () =>
                    import("@/views/products/products-sequence-groups/SequenceGroupView.vue"),
            },
            {
                path: "/services/service-page/:id",
                name: "service-page",
                meta: {
                    index: 0,
                },
                component: () =>
                    import(/* webpackChunkName: "company-page" */ "@/views/products/services/ServicePage.vue"),
                children: [
                    {
                        path: "/services/service-page/:id/edit-service",
                        name: "service-page-edit-service",
                        meta: {
                            index: 0,
                        },
                        component: () =>
                            import(/* webpackChunkName: "edit-company" */ "@/views/products/services/EditServiceWrapper.vue"),
                    },
                    // {
                    //   path: "/services/service-page/:id/employees-that-provide-the-service",
                    //   name: "service-page-employees-that-provide-the-service",
                    //   meta: {
                    //     index: 1,
                    //   },
                    //   component: () =>
                    //       import(/* webpackChunkName: "edit-company" */ "@/views/products/services/EmployeesThatProvideTheServiceWrapper.vue"),
                    // },
                    {
                        path: "/services/service-page/:id/employees-that-provide-the-service",
                        name: "service-page-employees-that-provide-the-service",
                        meta: {
                            index: 1,
                        },
                        component: () =>
                            import(/* webpackChunkName: "edit-company" */ "@/views/products/services/product-user/table-and-filter.vue"),
                    },
                    {
                        path: "/services/service-page/:id/add-user-to-product",
                        name: "service-page-add-user-to-product",
                        meta: {
                            index: 2,
                        },
                        component: () =>
                            import(/* webpackChunkName: "edit-company" */ "@/views/products/services/AddUserToProductWrapper.vue"),
                    },
                    {
                        path: "/services/service-page/:id/sales",
                        name: "service-page-sales",
                        meta: {
                            index: 3,
                        },
                        component: () =>
                            import(/* webpackChunkName: "service-page-sales" */ "@/views/products/products-sales/table-and-filter.vue"),
                    },
                    {
                        path: "/services/service-page/:id/simultaneous-services",
                        name: "service-page-simultaneous-services",
                        meta: {
                            index: 4,
                        },
                        component: () =>
                            import(/* webpackChunkName: "service-page-simultaneous-services" */ "@/views/products/services/SimultaneousServicesView.vue"),
                    },
                ],
            },
            {
                path: "/incomes",
                name: "incomes",
                meta: {
                    index: 0,
                },
                component: () =>
                    import(/* webpackChunkName: "company-page" */ "@/views/income-expense/income/IncomesPage.vue"),
                children: [
                    {
                        path: "/incomes/table-and-filter",
                        name: "incomes-table-and-filter",
                        meta: {
                            index: 0,
                        },
                        component: () =>
                            import("@/views/income-expense/income/table-and-filter/table-and-filter.vue"),
                    },
                    {
                        path: "/incomes/sales/table-and-filter",
                        name: "sales-table-and-filter",
                        meta: {
                            index: 1,
                        },
                        component: () =>
                            import("@/views/income-expense/sale/table-and-filter/table-and-filter.vue"),
                    },
                    {
                        name: 'prepaid',
                        path: 'prepaid',
                        meta: {
                            index: 2,
                        },
                        component: () => import('@/views/income-expense/prepaid/index.vue')
                    }
                ],
            },
            {
                path: "/expenses",
                name: "expenses",
                meta: {
                    index: 0,
                },
                component: () =>
                    import("@/views/income-expense/expense/ExpensesPage.vue"),
                children: [
                    {
                        path: "/expenses/table-and-filter",
                        name: "expenses-table-and-filter",
                        meta: {
                            index: 0,
                        },
                        component: () =>
                            import("@/views/income-expense/expense/table-and-filter/table-and-filter.vue"),
                    },
                    {
                        path: "/external-entities",
                        name: "external-entities-table-and-filter",
                        meta: {
                            index: 1,
                        },
                        component: () =>
                            import("@/views/external-entities/table-and-filter/table-and-filter.vue"),
                    },
                ],
            },
            {
                path: "/employees",
                name: "employees",
                meta: {
                    index: 0,
                },
                component: () =>
                    import(
                        /* webpackChunkName: "employees" */ "@/views/employees/EmployeesPage.vue"
                        ),
                children: [
                    {
                        path: "/employees/main",
                        name: "employees-main",
                        meta: {
                            index: 0,
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "employees/main" */ "@/views/employees/branchs-users/table-and-filter/table-and-filter.vue"
                                ),
                    },
                    {
                        path: "/firm-staff-only",
                        name: "firm-staff-only-table-and-filter",
                        meta: {
                            index: 1,
                            allStaff: false,
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "firms-users" */ "@/views/firms/firms-users/table-and-filter/table-and-filter.vue"
                                ),
                    },
                    {
                        path: "/firm-staff-all",
                        name: "firm-staff-all-table-and-filter",
                        meta: {
                            index: 2,
                            allStaff: true,
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "firms-users" */ "@/views/firms/firms-users/table-and-filter/table-and-filter.vue"
                                ),
                    },
                    {
                        path: "/employees/leaves",
                        name: "employees-leaves",
                        meta: {
                            index: 3,
                            leaveable_type: 'user',
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "leaves" */ "@/views/leaves/main.v1.vue"
                                ),
                    },
                ],

            },
            {
                path: "/employees/main/employee-page/:id",
                name: "employee-page",
                meta: {
                    index: 0,
                },
                component: () =>
                    import(/* webpackChunkName: "company-page" */ "@/views/employees/EmployeePage.vue"),
                children: [
                    {
                        path: "/employees/main/employee-page/:id/edit-employee",
                        name: "employee-page-edit-employee",
                        meta: {
                            index: 0,
                        },
                        component: () =>
                            import(/* webpackChunkName: "edit-company" */ "@/views/employees/EditEmployeeWrapper.vue"),
                    },
                    // {
                    //   path: "/employees/main/employee-page/:id/services-provided-by-the-employee",
                    //   name: "employee-page-services-provided-by-the-employee",
                    //   meta: {
                    //     index: 1,
                    //   },
                    //   component: () =>
                    //       import(/* webpackChunkName: "edit-company" */ "@/views/employees/ServicesProvidedByTheEmployeeWrapper.vue"),
                    // },
                    {
                        path: "/employees/main/employee-page/:id/services-provided-by-the-employee",
                        name: "employee-page-services-provided-by-the-employee",
                        meta: {
                            index: 1,
                        },
                        component: () =>
                            import(/* webpackChunkName: "edit-company" */ "@/views/employees/product-user/table-and-filter.vue"),
                    },
                    {
                        path: "/employees/main/employee-page/:id/add-product-to-user",
                        name: "employee-page-add-product-to-user",
                        meta: {
                            index: 2,
                        },
                        component: () =>
                            import(/* webpackChunkName: "edit-company" */ "@/views/employees/AddProductToUserWrapper.vue"),
                    },
                    {
                        path: "/employees/main/employee-page/:id/weekly-schedules/:model_type/:model_id",
                        name: "employee-page-weekly-schedules",
                        meta: {
                            index: 3,
                        },
                        component: () =>
                            import(/* webpackChunkName: "edit-company" */ "@/views/weekly-schedules/WeeklySchedules.vue"),
                    },
                    {
                        path: "/employees/main/employee-page/:id/sales",
                        name: "employee-page-sales",
                        meta: {
                            index: 4,
                        },
                        component: () =>
                            import(/* webpackChunkName: "users-sales-table-and-filter" */ "@/views/employees/users-sales/table-and-filter.vue"),
                    },
                ],
            },
            {
                path: "/clients",
                name: "clients",
                component: () =>
                    import(/* webpackChunkName: "clients" */ "@/views/clients/main.vue"),
            },
            {
                path: '/client/:code',
                name: 'client',
                component: () => import('@/views/clients/client.vue'),
                children: [
                    {
                        path: '',
                        name: 'client-profile',
                        component: () => import('@/views/clients/client/profile.vue')
                    },
                    {
                        path: 'appointments',
                        name: 'client-appointments',
                        component: () => import('@/views/clients/client/appointments.vue')
                    },
                    {
                        path: 'payments',
                        name: 'client-payments',
                        component: () => import('@/views/clients/client/payments.vue')
                    },
                    {
                        path: 'bundles',
                        name: 'client-bundles',
                        component: () => import('@/views/clients/client/bundles.vue')
                    },
                ]
            },
            {
                path: "/appointments-page",
                name: "appointments-page",
                component: () =>
                    import(/* webpackChunkName: "appointments" */ "@/views/appointments/AppointmentsPage.vue"),
                children: [
                    {
                        path: "/appointments/unexpired/",
                        name: "unexpired-appointments",
                        meta: {
                            index: 0,
                            expired: false,
                        },
                        component: () =>
                            import(/* webpackChunkName: "unexpired-appointments" */ "@/views/appointments/table-and-filter/table-and-filter.vue"),
                    },
                    {
                        path: "/appointments/expired",
                        name: "expired-appointments",
                        meta: {
                            index: 1,
                            expired: true,
                        },
                        component: () =>
                            import(/* webpackChunkName: "expired-appointments" */ "@/views/appointments/table-and-filter/table-and-filter.vue"),
                    },
                    {
                        path: "/appointments/operations",
                        name: "appointments-operations",
                        meta: {
                            index: 2,
                        },
                        component: () =>
                            import(/* webpackChunkName: "appointments-operations" */ "@/views/appointments/operations/table-and-filter/table-and-filter.vue"),
                    },
                ],
            },
            {
                path: "/make-sales/:key_suffix/:operation?",
                name: "make-sales",
                meta: {
                    generalPrefix: GeneralPrefixes.MakeSales
                },
                component: () =>
                    import(/* webpackChunkName: "appointments" */ "@/views/income-expense/sale/make-sales/make-sales.vue"),
            },
            {
                path: "/make-income-from-sales/:key_suffix/:operation?",
                name: "make-income-from-sales",
                meta: {
                    generalPrefix: GeneralPrefixes.MakeIncomeFromSales
                },
                component: () =>
                    import(/* webpackChunkName: "appointments" */ "@/views/income-expense/income/make-income-for-sales/make-income-from-sales.vue"),
            },
            {
                path: "/create-income-from-sales-and-pay-for-it/:key_suffix/:operation?",
                name: "create-income-from-sales-and-pay-for-it",
                meta: {
                    generalPrefix: GeneralPrefixes.CreateIncomeFromSalesAndPayForIt
                },
                component: () =>
                    import(/* webpackChunkName: "appointments" */ "@/views/income-expense/income/create-income-from-sales-and-pay-for-it/create-income-from-sales-and-pay-for-it.vue"),
            },
            {
                path: "/create-income-from-sales/:key_suffix",
                name: "create-income-from-sales",
                meta: {
                    generalPrefix: GeneralPrefixes.CreateIncomeFromSalesAndPayForIt,
                    isExpense: false,
                    isUpdate: false,
                },
                component: () =>
                    import("@/views/income-expense/payment/create-or-update-with-payments/create-or-update-with-payments.vue"),
            },
            {
                path: "/update-income-from-sales/:key_suffix",
                name: "update-income-from-sales",
                meta: {
                    generalPrefix: GeneralPrefixes.UpdateIncomeFromSales,
                    isExpense: false,
                    isUpdate: true,
                },
                component: () =>
                    import("@/views/income-expense/payment/create-or-update-with-payments/create-or-update-with-payments.vue"),
            },
            {
                path: "/make-expense-to-create/:key_suffix",
                name: "make-expense-to-create",
                meta: {
                    generalPrefix: GeneralPrefixes.MakeExpenseToCreate,
                    isUpdate: false,
                },
                component: () =>
                    import("@/views/income-expense/expense/make-expense/make-expense.vue"),
            },
            {
                path: "/make-expense-to-update/:key_suffix",
                name: "make-expense-to-update",
                meta: {
                    generalPrefix: GeneralPrefixes.MakeExpenseToUpdate,
                    isUpdate: true,
                },
                component: () =>
                    import("@/views/income-expense/expense/make-expense/make-expense.vue"),
            },
            {
                path: "/create-expense/:key_suffix",
                name: "create-expense",
                meta: {
                    generalPrefix: GeneralPrefixes.CreateExpenseAndPayForIt,
                    isExpense: true,
                    isUpdate: false,
                },
                component: () =>
                    import("@/views/income-expense/payment/create-or-update-with-payments/create-or-update-with-payments.vue"),
            },
            {
                path: "/update-expense/:key_suffix",
                name: "update-expense",
                meta: {
                    generalPrefix: GeneralPrefixes.UpdateExpense,
                    isExpense: true,
                    isUpdate: true,
                },
                component: () =>
                    import("@/views/income-expense/payment/create-or-update-with-payments/create-or-update-with-payments.vue"),
            },
            {
                path: "/branches",
                name: "branches",
                component: () =>
                    import(
                        /* webpackChunkName: "branches-update-general" */ "@/views/companies/branches/BranchPage.vue"
                        ),
                children: [
                    {
                        path: "/branches/update-general",
                        name: "branches-update-general",
                        component: () =>
                            import(
                                /* webpackChunkName: "branches-update-general" */ "@/components/modals/branch/update-general.vue"
                                ),
                        meta: {
                            index: 0
                        },
                    },
                    {
                        path: "/branches/appointment-settings",
                        name: "branches-appointment-settings",
                        component: () =>
                            import(
                                /* webpackChunkName: "branches-appointment-settings" */ "@/views/companies/branches/AppointmentSettingsWrapper.vue"
                                ),
                        meta: {
                            index: 1
                        },
                    },
                    {
                        path: "/branches/update-weekly-schedules/:model_type/:model_id",
                        name: "branches-weekly-schedules",
                        component: () =>
                            import(
                                /* webpackChunkName: "branches-weekly-schedules" */ "@/views/weekly-schedules/WeeklySchedules.vue"
                                ),
                        meta: {
                            index: 2
                        },
                    },
                    {
                        path: "/branches/update-address",
                        name: "branches-update-address",
                        component: () =>
                            import(
                                /* webpackChunkName: "branches-update-address" */ "@/views/companies/branches/UpdateAddressWrapper.vue"
                                ),
                        meta: {
                            index: 3
                        },
                    },
                    {
                        path: "/branches/leaves",
                        name: "branches-leaves",
                        component: () =>
                            import(
                                /* webpackChunkName: "branches-leaves" */ "@/views/leaves/table-and-filter/table-and-filter.vue"
                                ),
                        meta: {
                            index: 4
                        },
                    },
                ]
            },
            {
                path: "/firms",
                name: "firms",
                component: () =>
                    import(
                        /* webpackChunkName: "firms-page" */ "@/views/companies/firms/FirmPage.vue"
                        ),
                children: [
                    {
                        path: "/firms/update-general",
                        name: "firms-update-general",
                        component: () =>
                            import(
                                /* webpackChunkName: "firms-update-general" */ "@/components/modals/firms/update-general.vue"
                                ),
                        meta: {
                            index: 0
                        },
                    },
                    {
                        path: "/firms/update-address",
                        name: "firms-update-address",
                        component: () =>
                            import(
                                /* webpackChunkName: "firms-update-address" */ "@/views/companies/firms/UpdateAddressWrapper.vue"
                                ),
                        meta: {
                            index: 1
                        },
                    },
                ]
            },
            {
                path: "/sms",
                name: "sms",
                component: () =>
                    import(
                        /* webpackChunkName: "sms-page" */ "@/views/sms/SmsPage.vue"
                        ),
                children: [
                    {
                        path: "/sms-messages/firm",
                        name: "sms-messages-table-firm",
                        meta: {
                            index: 1,
                            isFirm: true,
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "sms-messages-table-firm" */ "@/views/sms/sms-logs/table-and-filter/table-and-filter.vue"
                                ),
                    },
                    {
                        path: "/sms-messages/branch",
                        name: "sms-messages-table-branch",
                        meta: {
                            index: 1,
                            isFirm: false,
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "sms-messages-table-branch" */ "@/views/sms/sms-logs/table-and-filter/table-and-filter.vue"
                                ),
                    },
                    {
                        path: "/sms-credit-events",
                        name: "sms-credit-events-table",
                        meta: {
                            index: 2,
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "sms-credit-events-table" */ "@/views/sms/sms-credit-events/table-and-filter/table-and-filter.vue"
                                ),
                    },
                    {
                        path: "/sms-credit-delta-stats",
                        name: "sms-credit-delta-stats",
                        meta: {
                            index: 3,
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "sms-credit-delta-stats" */ "@/views/sms/sms-credit-delta-stats/table-and-filter/table-and-filter.vue"
                                ),
                    },
                    {
                        path: "/sms-tasks/branch",
                        name: "sms-tasks-table-branch",
                        meta: {
                            index: 0,
                            isFirm: false,
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "sms-tasks-table-branch" */ "@/views/sms/sms-tasks/sms-tasks-table-and-filter.vue"
                                ),
                    },
                    {
                        path: "/sms-tasks/firm",
                        name: "sms-tasks-table-firm",
                        meta: {
                            index: 0,
                            isFirm: true,
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "sms-tasks-table-branch" */ "@/views/sms/sms-tasks/sms-tasks-table-and-filter.vue"
                                ),
                    },
                    {
                        path: "/sms-tasks/:task/messages",
                        name: "sms-task-messages-table",
                        meta: {},
                        component: () =>
                            import(
                                /* webpackChunkName: "sms-task-messages-table" */ "@/views/sms/sms-tasks/sms-task-details/sms-task-messages-table-and-filter.vue"
                                ),
                    },
                ]
            },
            {
                path: "/bundles",
                name: "bundles",
                component: () =>
                    import(
                        /* webpackChunkName: "bundles-page" */ "@/views/products/bundles/BundlesPage.vue"
                        ),
                children: [
                    {
                        path: "/bundles/show",
                        name: "bundles-table",
                        component: () =>
                            import(
                                /* webpackChunkName: "bundles-table" */ "@/views/products/bundles/table/bundles-table-and-filter.vue"
                                ),
                        meta: {
                            index: 0
                        },
                    }
                ]
            },
            {
                path: "/send-sms",
                name: "send-sms",
                component: () =>
                    import(
                        /* webpackChunkName: "send-sms-page" */ "@/views/sms/send-sms/SendSmsPage.vue"
                        ),
                children: [
                    {
                        path: "/send-sms/create",
                        name: "send-sms-message",
                        meta: {
                            index: 0
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "send-sms-message" */ "@/views/sms/send-sms/CreateSmsMessage.vue"
                                ),
                    },
                ]
            }
        ],
    },
    {
        path: "/login",
        name: "login",
        component: () =>
            import(/* webpackChunkName: "login" */ "@/views/auth/login.vue"),
    },
    {
        path: "/404",
        name: "error-page",
        component: () =>
            import(/* webpackChunkName: "error" */ "@/views/errors/404.vue"),
    },
    {
        path: "/403/:message?",
        name: "403",
        component: () =>
            import(/* webpackChunkName: "error" */ "@/views/errors/403.vue"),
    },
    {
        path: "/banned",
        name: "banned",
        component: () =>
            import(/* webpackChunkName: "banned" */ "@/views/errors/Banned.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        component: () =>
            import(/* webpackChunkName: "error" */ "@/views/errors/404.vue"),
    },
    {
        path: "/add-user/accept/:token/:accept/:setPassword",
        name: "add-user-accept",
        meta: {
            mode: 0
        },
        component: () =>
            import(
                /* webpackChunkName: "add-user-accept" */ "@/views/employees/SetLoginCredentialsForUser.vue"
                ),
    },
    {
        path: "/add-user/verify-phone/:token",
        name: "add-user-verify-phone",
        meta: {
            requestFunction: verifyPhoneOfNewUserToAddUser
        },
        component: () =>
            import(
                /* webpackChunkName: "add-user-verify-phone" */ "@/views/employees/VerifyPhoneOfUserThatWillLogIn.vue"
                ),
    },
    {
        path: "/allow-log-in/verify-phone/:token",
        name: "allow-log-in-verify-phone",
        meta: {
            requestFunction: verifyPhoneOfExistingUserThatWillLogIn
        },
        component: () =>
            import(
                /* webpackChunkName: "allow-log-in-verify-phone" */ "@/views/employees/VerifyPhoneOfUserThatWillLogIn.vue"
                ),
    },
    {
        path: "/allow-log-in/complete/:token",
        name: "allow-log-in-complete",
        meta: {
            mode: 1
        },
        component: () =>
            import(
                /* webpackChunkName: "allow-log-in-complete" */ "@/views/employees/SetLoginCredentialsForUser.vue"
                ),
    },
    {
        path: "/user/verify-email/:token",
        name: "user-verify-email",
        component: () =>
            import(
                /* webpackChunkName: "user-verify-email" */ "@/views/user/VerifyNewEmailAddress.vue"
                ),
    },
    {
        path: "/user-panel",
        name: "user-panel-layout",
        meta: {
            auth: true
        },
        component: () =>
            import(
                /* webpackChunkName: "user-panel-layout" */ "@/layouts/UserPanelLayout.vue"
                ),
        children: [
            {
                path: "/user-panel",
                name: "user-panel-home",
                component: () =>
                    import(
                        /* webpackChunkName: "user-panel-home" */ "@/views/user-panel/HomePage.vue"
                        ),
                children: [
                    {
                        path: "/user-panel/my-account",
                        name: "user-panel-my-account",
                        meta: {
                            index: 0
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "user-panel-my-account" */ "@/views/user-panel/MyAccount.vue"
                                ),
                    },
                    {
                        path: "/user-panel/cp",
                        name: "user-panel-cp",
                        meta: {
                            index: 1
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "user-panel-cp" */ "@/views/user-panel/ChangePassword.vue"
                                ),
                    },
                ]
            },
        ]
    },
    {
        path: "/password-reset",
        name: "reset-password",
        meta: {
            auth: false
        },
        component: () =>
            import(
                /* webpackChunkName: "user-panel-layout" */ "@/views/auth/password-reset.vue"
                ),
    }
];

export {
    routes,
}
